import * as React from "react"
import Img from "react-cool-img"
import { STRAPI_API } from "../consts/api"
import SocialNetworks from "./SocialNetworks"

export default function TeachersDetailComp(props) {
  const { data } = props

  return (
    <div className="teachersDetail">
      <div className="teachersDetail__block">
        {
          // PHOTO
          data?.photo.url && (
            <div className="teachersDetail-img">
              <Img src={STRAPI_API + data.photo.url} alt="img" />
            </div>
          )
        }

        <div className="teachersDetail-cont">
          <div className="teachersDetail-text">
            {data.djName && <h1> {data.djName} </h1>}

            {<h2>{data.name}</h2>}

            {/*{*/}
            {/*    data?.teacherCard?.subtitle &&*/}
            {/*    <p>{data.teacherCard.subtitle}</p>*/}
            {/*}*/}

            {data?.mainDescription && (
              <p className="teachersDetail-text--description">
                {data.mainDescription}
              </p>
            )}
          </div>

          {data?.socialLinks && <SocialNetworks data={data.socialLinks} />}
        </div>
      </div>
    </div>
  )
}
