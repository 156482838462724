import * as React from "react"
import { useState } from "react"
import { AnimatePresence } from "framer-motion"

import "./teachersDetail.scss"
import Layout from "../../components/Layout"
import SidebarMenuAbout from "../../components/sideBarMenu/SidebarMenuAbout"
import DoubleBlock from "../../components/doubleBlock/DoubleBlock"
import { graphql, Link } from "gatsby"
import TeachersDetailComp from "../../components/TeachersDetailComp"
import SliderImages from "../../components/sliders/SliderImages"
import HelpChoosing from "../../components/offers/HelpChoosing"
import GalleryPopup from "../../components/popups/GalleryPopup"
import FormPopup from "../../components/popups/FormPopup"

import { MENU_LINKS } from "../../consts/MenuLinks"
import {SEO} from "../../components/seo";

const TeachersDetail = ({ data, location }) => {
  const { allStrapiTeacher } = data

  const teacherData =
    allStrapiTeacher?.nodes?.length === 1 ? allStrapiTeacher?.nodes[0] : null

  const [formPopup, setFormPopup] = useState(false)
  const [popUpGallery, setPopUpGallery] = useState(false)
  const [galleryElem, setGalleryElem] = useState(false)

  return (
    <div>
      <SEO title={teacherData?.titleDesc?.pageTitle} description={teacherData?.titleDesc?.pageDescription}/>
      <Layout classAdd="teachersDetailPage" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          <div className="link-back">
            <Link to={MENU_LINKS.teachers}> Преподаватели </Link>
          </div>

          {teacherData && <TeachersDetailComp data={teacherData} />}

          {
            // TEXT DESCRIPTION 1
            teacherData?.description1 && (
              <div className="text-block">
                <p className="teachers-text--description">
                  {teacherData.description1}
                </p>
              </div>
            )
          }

          {
            // PHOTO SLIDER
            teacherData?.sliderImg && (
              <div>
                <SliderImages
                  data={teacherData.sliderImg}
                  positionCenter={false}
                  setOpen={setPopUpGallery}
                  open={popUpGallery}
                  setGalleryElem={setGalleryElem}
                />
              </div>
            )
          }

          {
            // TEXT DESCRIPTION 2
            teacherData?.description2 && (
              <div className="text-block">
                <p className="teachers-text--description">
                  {teacherData.description2}
                </p>
              </div>
            )
          }

          {
            // OFFER
            teacherData?.offer && (
              <HelpChoosing classAdd="m140" data={teacherData.offer} />
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {popUpGallery && teacherData?.sliderImg && (
          <GalleryPopup
            open={popUpGallery}
            setOpen={setPopUpGallery}
            galleryElem={galleryElem}
            data={teacherData.sliderImg}
          />
        )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
      allStrapiTeacher(filter: { slug: { eq: $slug } }) {
          nodes {
              slug
              djName
              name
              mainDescription
              teacherCard {
                  cover {
                      url
                  }
                  subtitle
              }
              photo {
                  url
              }
              offer {
                  title
                  subtitle
                  button
              }
              socialLinks {
                  link
                  socials
              }
              description1
              description2
              sliderImg {
                  url
              }
              titleDesc {
                  pageTitle
                  pageDescription
              }
          }
      }
  }
`


export default TeachersDetail
